import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EnvelopeFill, TelephoneForwardFill } from 'react-bootstrap-icons';
import { Link, NavLink } from 'react-router-dom';
import Facebook from '../assets/fb2.png';
import insta from '../assets/insta2.png';
import linkdin from '../assets/linkedin2.png';
import footer_logo from '../assets/logo32.png';

const Footer = () => {
  // const handleClickTop = () => {
  //   window.scrollTo(0, 0);
  // };

  return (
    <>
      <div id="footer">
        {/* know section */}
        <div className="wrapper mt-10" style={{ background: '#094669', color: 'white' }}>
          <Row className="flex justify-between width-1800 equal-width" style={{ paddingTop: '40px', borderRadius: '20px', margin: 'auto', paddingBottom: '40px', }}>
            <Col md={12} lg={6} style={{ width: '40%' }}>
              {/* <Fade direction="up" cascade damping={0.3} triggerOnce={true}> */}
              <div className="footer_logo mb-4">
                <Link to="/">
                  <img src={footer_logo} alt="footer_logo" style={{ width: '70%' }} />
                </Link>
              </div>
              <p className="font18-light address md:pr-24" style={{ lineHeight: '30px' }}>
                In partnership with Orange Neurosciences, Rising Technologies provides ReadON Arabia in Bahrain and the GCC region. Orange Neurosciences, Canada, owns ReadON intellectual property, technology, services and all associated brands, including ReadON Arabia.
              </p>
              {/* </Fade> */}
            </Col>

            <Col md={12} lg={6} style={{ width: '37%' }}>
              <div className="footer-info">
                {/* <Fade direction="up" cascade damping={0.3} triggerOnce={true}> */}
                <h6 className="font24-bold mt-8 md:mt-0">Address: </h6>

                {/* <h6 className="mt-5 font18-bold">Bahrain</h6> */}
                <p className="mt-2 text-white flex font18-light">Office #2005, Blgd 934, Road 1015, Block 410 - Sanabis,
                  Farooq Center, Manama, Kingdom of Bahrain</p>
                {/* <p className="font18-light">Muharraq, Bahrain</p> */}
                <ul className="mt-5 address-list">
                  <li className="mb-3">
                    <Row className="address-item">
                      <Col lg={1} md={1} xs={1}>
                        <EnvelopeFill className="address-icon" border="white" size={17} />
                      </Col>
                      <Col lg={11} md={11} xs={11}>
                        <p className="font18-light text-primary-color">
                          <b>Email:</b>{' '}
                          <a href="mailto:talktous@orangeneurosciences.com" className="text-white">
                            talktous@orangeneurosciences.com
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row className="address-item">
                      <Col lg={1} md={1} xs={1}>
                        <TelephoneForwardFill className="address-icon" size={17} />
                      </Col>
                      <Col lg={11} md={11} xs={11}>
                        <p className="font18-light text-primary-color">
                          <b>Phone:</b>{' '}
                          <a href="tel:+97366739300" className="text-white">
                            +97366739300
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <div className="social_icon mt-5">
                      <ul>
                        <li>
                          <Link to="https://www.facebook.com/ReadON.Arabia/?ref=page_internal" target="_blank">
                            <img src={Facebook} alt="Facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/readon.arabia/" target="_blank">
                            <img src={insta} alt="insta" />
                          </Link>
                        </li>
                        <li>
                          <Link to="https://www.linkedin.com/company/readon-arabia/" target="_blank">
                            <img src={linkdin} alt="linkdin" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                {/* </Fade> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="wrapper" style={{ background: '#094669', color: 'white' }}>
        <Row className="flex flex-col md:flex-row justify-between width-1800 equal-width items-center" style={{ margin: 'auto', borderTop: '1px solid white', padding: '10px 0', opacity: '85%' }}>
          <Col md={12} lg={6} className="flex items-center">
            <div className="footer-info flex">
              <NavLink to="/privacy-policy" className="footer-link font15" style={{ marginLeft: '0px' }}>Privacy Policy</NavLink>
              <NavLink to="/refund-policy" className="footer-link font15">Refund Policy</NavLink>
              <NavLink to="/terms-of-use" className="footer-link font15">Terms Of Use</NavLink>
            </div>
          </Col>

          <Col md={12} lg={6} className="py-2" style={{ marginBottom: '0px' }}>
            <p
              className="text-center font15"
              style={{
                letterSpacing: '1px',
                margin: '0',
              }}
            >
              <i>Copyright © {new Date().getFullYear()} Orange Neurosciences Corporation</i>
            </p>
          </Col>
        </Row>
      </div>

      <style jsx>{`
        @media (max-width: 1000px) {
          #footer .flex {
            display: flex;
            flex-wrap: wrap;
          }
          #footer .flex > div {
            flex: 1 100%; /* First and second columns take 50% width */
          }
          #footer .align-content-center {
            flex: 1 100%; /* Third column takes 100% width below the first two */
          }
        }
        .address-item {
          display: flex;
          align-items: center;
        }
        .address-icon {
          margin-right: 10px; /* Space between icon and text */
        }
        .address-item p {
          margin: 0;
        }
      `}</style>
    </>
  );
};

export default Footer;
